
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        

































































$bp: m;

.banner-speedlines {
  position: relative;
}

.banner-speedlines-inner {
  max-width: 83rem;
  margin: -2rem 0;
  text-align: center;
  background: $white;

  .is-dark & {
    background-color: $c-midnight-darker;
  }

  @include mq(xs) {
    padding: 3rem 3.5rem;
    border-radius: 4px;
    box-shadow: 0 10px 40px rgba($black, 0.1);
  }

  @include mq($bp) {
    display: flex;
    margin: 0 auto;
    padding: 0;
    text-align: left;
    border-radius: 4px;
    box-shadow: 0 10px 40px rgba($black, 0.1);
  }
}

.banner-speedlines__speedlines {
  @include center-x;

  z-index: -1;
  transform: translateX(0);
}

.banner-speedlines__icon {
  flex-shrink: 0;
  margin-bottom: 1.5rem;
  text-align: center;
  fill: $c-pink-medium;

  .icon {
    max-height: 4.5rem;
  }

  @include mq($bp) {
    position: relative;
    overflow: hidden;
    width: 21rem;
    margin-right: 5rem;
    margin-bottom: 0;

    .icon {
      position: relative;
      z-index: 1;
      width: auto;
      height: 100%;
      max-width: 9.5rem;
      max-height: 9.5rem;
      margin-top: 7.2rem;
      margin-left: 4.6rem;
      fill: $white;
    }

    &::before {
      @include get-all-space;

      content: '';
      top: 3.6rem;
      left: 0;
      width: 15.5rem;
      background: $c-pink-medium;
      border-radius: 180px 180px 0 0;
      transform: rotate(36deg);
    }
  }
}

.banner-speedlines__content {
  .is-dark & {
    color: $white;
  }

  @include mq($bp) {
    padding: 6rem 10rem 6rem 0;
  }
}

.banner-speedlines__title {
  @include fluid-type(20, 28);

  margin: 0 0 2rem;
}

.banner-speedlines__text {
  @extend %fw-light;
  @include fluid-type(15, 18);

  line-height: 1.6;
}

.banner-speedlines__links {
  margin-top: $spacing;
}

.banner-speedlines__link {
  margin-right: $spacing;
}
