
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        














































































































































.mobile-cards {
  overflow: hidden;
}

.mobile-cards__title {
  @extend %fw-normal;

  margin: 0 0 2rem;
  text-align: center;
}

.mobile-cards__promo {
  text-align: center;
}

.mobile-cards__promo__label {
  .label {
    margin-block: 1.6rem;
    font-size: 1.5rem;
  }
}

.mobile-cards__promo__text {
  margin-bottom: 3rem;
}

.mobile-cards__link-outer {
  display: flex;
}

.mobile-cards__link {
  display: inline-flex;
  align-items: center;
  margin-inline: auto;
  margin-bottom: $spacing * 3;
  text-decoration: none;

  svg {
    width: 2rem;
    height: 2rem;
    margin-right: 0.6rem;
  }

  span {
    padding-bottom: 2px;
    color: $c-anthracite-light;
    font-size: 1.4rem;
    line-height: 1.6rem;

    &::after {
      background-color: rgba($c-anthracite-light, 0.2);
    }
  }
}

::v-deep {
  .carousel__item {
    @include mq($from: l) {
      flex-grow: 1;
      flex-shrink: 1;
      max-width: 38rem; // Arbitrary value
    }
  }

  .product-card {
    width: 100%;
  }
}
